/* styles.css */
::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-track {
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 4px;
}
