@tailwind base;
@tailwind components;
@tailwind utilities;
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-icons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";
.ql-size-small {
  font-size: 0.75em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-huge {
  font-size: 2.5em;
}
/* App.css */

ul {
  list-style-type: disc; /* Bullet points for unordered lists */
  margin-left: 20px; /* Indent for better readability */
  color: #333; /* Darker text color */
}

ol {
  list-style-type: decimal; /* Numbers for ordered lists */
  margin-left: 20px;
  color: #333;
}

li {
  margin-bottom: 10px; /* Space between list items */
  font-size: 16px; /* Larger font size */
}
.ql-toolbar {
  background-color: #cdc1ff;
  border-radius: 4px;
}
.ql-image {
  /* display: none; */
  visibility: hidden;
}
